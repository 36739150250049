import { Layout, Row, Col, Button, Typography } from 'antd';
import './PlatformDescription.less'
import ru from '../../../ru.png'
import desc from '../../../desc.png'
import photo from '../../../photo.png'
import star from '../../../star.png'
import delivery from '../../../delivery.png'

const { Title, Text } = Typography;

const PlatformDescription = () => {
    return (
        <div className="search-page">
          {/* Шапка с жёлтым фоном */}
          <div className="header-section">
            <Row gutter={[16, 16]} justify="center">
                <Col xs={24} sm={24} md={8} lg={8}>
                  <div className="header-column" style={{alignItems : 'end', textAlign : 'end'}}>
                    <Title level={2} className="header-title">
                      Удобный поиск товаров
                    </Title>
                    <div className="header-subtitle">
                      <Text className="header-subtitle-text">в Китае</Text>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                  <div className="header-column">
                    <img src='/logo.png' alt='logo' className="header-logo"/>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                  <div className="header-column" style={{alignItems : 'start', textAlign : 'start'}}>
                    <Title level={2} className="header-title">Прибыльный бизнес</Title>
                    <div className="header-subtitle">              
                      <Text className="header-subtitle-text">Нужный товар</Text>
                      <Text className="header-subtitle-text">Надёжный поставщик</Text>
                    </div>
                  </div>
                </Col>
            </Row>
          </div>
    
          {/* Блок с карточками */}
          <div className="features-section">
            <Title level={3} className="features-title">
              Всё, что нужно селлеру:
            </Title>
            <Row gutter={[16, 16]} justify="space-between">
              <Col xs={24} sm={12} md={8} lg={4}>
                <div className="feature-card">
                  <img
                    src={ru}
                    alt="RU"
                    className="feature-icon"
                  />
                  <Title level={4}>Всё на русском</Title>
                  <Text>Никаких языковых барьеров</Text>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} lg={4}>
                <div className="feature-card">
                  <img
                    src={desc}
                    alt="Поиск по описанию"
                    className="feature-icon"
                  />
                  <Title level={4}>Поиск по описанию</Title>
                  <Text>Умный поиск товаров или поставщиков по любой фразе</Text>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} lg={4}>
                <div className="feature-card">
                  <img
                    src={photo}
                    alt="Поиск по фото"
                    className="feature-icon"
                  />
                  <Title level={4}>Поиск по фото</Title>
                  <Text>Достаточно загрузить фото, чтобы найти нужный товар</Text>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} lg={4}>
                <div className="feature-card">
                  <img
                    src={star}
                    alt="Выбор поставщика"
                    className="feature-icon"
                  />
                  <Title level={4}>Выбор поставщика</Title>
                  <Text>Рейтинги по 5+ показателям для выбора надежного поставщика</Text>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} lg={4}>
                <div className="feature-card">
                  <img
                    src={delivery}
                    alt="Доставка из Китая"
                    className="feature-icon"
                  />
                  <Title level={4}>Доставка из Китая</Title>
                  <Text>Выбор из проверенных транспортных компаний, отслеживание заказа</Text>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      );
}

export default PlatformDescription;