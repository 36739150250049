import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import '../../../constants/enums';
import {product1688, productByUrl1688, productRus, qa, productDetail1688} from "../../../services/apiService";
import {search1688Slice} from "../search/search1688Slice";

export const product1688Slice = createSlice({
    name: 'product',
    initialState: {
        loading: false,
        productId: undefined,
        product: {},
        // currentImage: undefined,
        currentImageIndex: undefined,
        currentProps:{},
        qa:{},
        detail: '',
        detailImgs: []
    },
    reducers: {
        setCurrentImageIndex: (state, {payload}) => {
            state.currentImageIndex =payload;
        },
        setCurrentImage: (state, {payload}) => {
            const mainImgs = [...(state.product?.mainImgs || [])];
            console.log("mainImgs:", mainImgs);
            const index = mainImgs.findIndex(img => img === payload);
            if (index !== -1 ) {
                state.currentImageIndex=index;
            }
        },
        setCurrentProp: (state, {payload}) => {
            const { pid, vid } = payload;
            if (typeof pid !== "undefined" && typeof vid !== "undefined") {
                state.currentProps = { ...state.currentProps, [pid]: vid };
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(productsRequestAsync.pending, (state, action) => {
                const {productId} = action.meta.arg;
                state.loading = true;
                state.productId = productId;
            })
            .addCase(productsRequestAsync.fulfilled, (state, action) => {
                const {mainImgs = [], skuProps = []} = action.payload;
                let currentProps = {};
                skuProps.forEach((prop, index)=> {
                    const {pid, values = []} = prop;
                    if(index !== skuProps.length -1 ) {
                        if (values.length > 0) {
                            currentProps[pid] = values[0].vid;
                        }
                    }
                })
                state.currentProps = currentProps;
                state.loading = false;
                state.product = action.payload;
                state.currentImageIndex = mainImgs.length > 0 ? 0 : undefined;

            })
            .addCase(productsRequestAsync.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(productDetailRequestAsync.fulfilled, (state, action) => {

                var offer_details = action.payload.replace('var offer_details=', '');
                if (offer_details.endsWith(";")) {
                    offer_details = offer_details.slice(0, -1);
                }
                var content = JSON.parse(offer_details).content;
                const parser = new DOMParser();
                const doc = parser.parseFromString(content, "text/html");

                state.detailImgs = Array.from(doc.querySelectorAll("img")).map(img => img.src);
            })
            .addCase(searchProductByUrl.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(searchProductByUrl.fulfilled, (state, action) => {
                const {itemId, mainImgs = [], skuProps = []} = action.payload;
                state.productId = itemId;
                let currentProps = {};
                skuProps.forEach((prop, index)=> {
                    const {pid, values = []} = prop;
                    if(index !== skuProps.length -1 ) {
                        if (values.length > 0) {
                            currentProps[pid] = values[0].vid;
                        }
                    }
                })
                state.currentProps = currentProps;
                state.loading = false;
                state.product = action.payload;
                state.currentImageIndex = mainImgs.length > 0 ? 0 : undefined;

            })
            .addCase(searchProductByUrl.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(productRequestAsyncRus.pending, (state, action) => {
                const {productId} = action.meta.arg;
                state.loading = true;
                state.productId = productId;
            })
            .addCase(productRequestAsyncRus.fulfilled, (state, action) => {
                const {mainImgs = [], skuProps = []} = action.payload;
                let currentProps = {};
                /* skuProps.forEach((prop, index)=> {
                    const {pid, values = []} = prop;
                    if(index !== skuProps.length -1 ) {
                        if (values.length > 0) {
                            currentProps[pid] = values[0].vid;
                        }
                    }
                }) */
                state.currentProps = currentProps;
                state.loading = false;
                state.product = action.payload;
                state.currentImageIndex = mainImgs.length > 0 ? 0 : undefined;

            })
            .addCase(productRequestAsyncRus.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(domainQa.fulfilled, (state, action) => {
                state.qa = action.payload;
            })
    }
})

export const {setCurrentImageIndex, setCurrentImage, setCurrentProp} = product1688Slice.actions;

export const productsRequestAsync = createAsyncThunk('product/productsRequestAsync',
    ({productId}) => {
    return product1688(productId);
})

export const productDetailRequestAsync = createAsyncThunk('product/productDetailRequestAsync',
    ({detailUrl}) => {
    return productDetail1688(detailUrl);
})

export const searchProductByUrl = createAsyncThunk('product/searchProductByUrl',
    ({url}) => {
    return productByUrl1688(url);
})

export const productRequestAsyncRus = createAsyncThunk('product/productRequestAsync',
    ({productId}) => {
    return productRus(productId);
})

export const domainQa = createAsyncThunk('domain/qa',
    ({domain}) => {
    return qa(domain);
})

export default product1688Slice.reducer;
