import React from 'react'


import './ProductCollection.less'
import {useSelector} from "react-redux";
import ProductCard from "./ProductCard";

const ProductCollection = () => {
    const productsResult = useSelector(state => state.search1688.productsResult)
    const {products} = productsResult;

    return (
        <div className='product-collection'>
        {
            products.map(product => (
                <ProductCard key={product.itemId} product={product}/>
            ))
        }
        </div>
    )
}

export default ProductCollection;
