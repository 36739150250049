import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {searchProducts, searchFactories, searchUploadImage, searchProductsByImage} from "../../../services/apiService";
import '../../../constants/enums';
import {FactoriesSortTypes, ProductsSortTypes, SearchTypes} from "../../../constants/enums";

export const search1688Slice = createSlice({
    name: 'search',
    initialState: {
        loading: false,
        searchType: SearchTypes.PRODUCTS,
        searchCount: 0,

        productsResult: {
            keywords: "",
            originalKeywords: "",
            imageUrl: undefined,
            products: [],
            pagination: {},
            sort: ProductsSortTypes.DEFAULT
        },
        factoriesResult: {
            keywords: "",
            originalKeywords: "",
            factories: [],
            pagination: {},
            sort: FactoriesSortTypes.DEFAULT
        }
    },
    reducers: {
        setSearchType: (state, {payload}) => {
            state.searchType =payload;
        },
        setImageUrl: (state, {payload}) => {
            state.productsResult = {
                ...state.productsResult,
                imageUrl : payload
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadImageAsync.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(searchProductsAsync.pending, (state, action) => {
                const {keywords, sort} = action.meta.arg;
                state.productsResult = {
                    ...state.productsResult,
                    keywords,
                    sort,
                    originalKeywords: undefined
                }
                state.loading = true;
                state.searchCount++;
            })
            .addCase(searchProductsAsync.fulfilled, (state, action) => {
                const {originalKeywords, products, keywords, sort} = action.payload;
                const { content: data = [], pageable: page, totalElements} = products;
                const pagination = {
                    total: totalElements,
                    pageSize: page.pageSize,
                    current: page.pageNumber
                };
                state.productsResult = {
                    ...state.productsResult,
                    originalKeywords,
                    keywords,
                    sort,
                    products: data,
                    pagination
                }
                state.loading = false;
                state.imageUrl = undefined;
            })
            .addCase(searchProductsAsync.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(searchFactoriesAsync.pending, (state, action) => {
                const {keywords, sort} = action.meta.arg;
                state.factoriesResult = {
                    ...state.factoriesResult,
                    keywords,
                    sort,
                    originalKeywords: undefined
                }
                state.loading = true;
                state.searchCount++;
            })
            .addCase(searchFactoriesAsync.fulfilled, (state, action) => {
                const {originalKeywords, factories, keywords, sort} = action.payload;
                const { content: data = [], pageable: page, totalElements} = factories;
                const pagination = {
                    total: totalElements,
                    pageSize: page.pageSize,
                    current: page.pageNumber
                };
                state.factoriesResult = {
                    ...state.factoriesResult,
                    originalKeywords,
                    keywords,
                    sort,
                    factories: data,
                    pagination
                }
                state.loading = false;
            })
            .addCase(searchFactoriesAsync.rejected, (state, action) => {
                state.loading = false;
            })
    }
})

export const {setSearchType, setImageUrl} = search1688Slice.actions;

export const searchProductsAsync = createAsyncThunk('search/searchProductsAsync',
    ({keywords, originalKeywords, pagination, sort}, thunkAPI) => {

    let {imageUrl} = thunkAPI.getState().search1688.productsResult;
    if(keywords) {
        imageUrl = undefined;
        thunkAPI.dispatch(setImageUrl(undefined));
    }

    if(imageUrl)
        return searchProductsByImage(imageUrl, pagination, sort);
    else
        return searchProducts(keywords, originalKeywords, pagination, sort);
})

export const uploadImageAsync = createAsyncThunk('search/uploadImageAsync',
    ({file}, thunkAPI ) => {
    searchUploadImage(file)
        .then((response) => {
            // const url = 'https://img.cdn.tmapi.top/search/imgextra3/1e32-8e86-4e4b-b686-1f0469e92249.jpg'
            thunkAPI.dispatch(setImageUrl(response));
            thunkAPI.dispatch(searchProductsAsync({
                keywords: undefined,
                originalKeywords: undefined,
                pagination: undefined,
                sort: undefined
            }))
        })
})


export const searchFactoriesAsync = createAsyncThunk('search/searchFactoriesAsync',
    ({keywords, originalKeywords, pagination, sort}) => {
    return searchFactories(keywords, originalKeywords, pagination, sort);
})


export default search1688Slice.reducer;
