import React from 'react'


import './ProductCard.less'
import {Link} from "react-router-dom";
import { Factory, Store, Handshake, Settings, MapPin, RefreshCcw, ShoppingCart, PackageCheck  } from "lucide-react";
import StarRating from "../../../../components/StarRating";

const ProductCard = ({product = {}}) => {

    const {itemId, title, originalTitle,
        img, price = 0, productUrl,
        quantityBegin, quantityPrices = [],
        shopInfo = {},
        deliveryInfo = {}, saleInfo = {},
        itemRepurchaseRate, goodsScore
    } = product;
    const {companyName, bizType} = shopInfo;
    const {areaFrom = []} = deliveryInfo;
    const {saleQuantityInt, ordersCount} = saleInfo;
    const fabricusUrl = `/1688/products/${itemId}`;

    const getSupplierIcon = (bizType) => {
        switch (bizType) {
            case "FACTORY":
                return <Factory />;
            case "STORE":
                return <Store />;
            case "HANDSHAKE":
                return <Handshake />;
            case "SERVICES":
                return <Settings />;
            default:
                return null;
        }
    };

    return(
        <div className='product-card-container'>
            <div className='product-card balance-row-1'>
                <div className='header'>
                    <div className='product-card-vendor-title'></div>
                    <div className='product-card-media position-relative'>
                        <Link to={`/1688/products/${itemId}`}>
                            <img alt={title} src={img} className='w-100 h-100 {/*overlay-ui*/}'/>
                        </Link>
                    </div>

                </div>
                <div className='content'>
                    <div className='vendor-title'>
                        { goodsScore && <div className='goods-score'><StarRating score={goodsScore}/></div> }
                        <div className='product-card_original-title'>
                            {getSupplierIcon(bizType)}
                            <Link to={`${productUrl}`}  target='_blank' rel="noreferrer" >
                                {companyName}
                            </Link>
                        </div>
                        <h3 className='product-card_title'>
                            <Link to={`/1688/products/${itemId}`} >
                                {title}
                            </Link>
                        </h3>
                    </div>
                    <div className='short-description'>
                        <ul className="product-info-list">
                            <li className="product-info-item">
                                <span className="icon"><MapPin className="w-5 h-5 text-[#007BFF]"/></span>
                                <span className="label">Отправка из: </span>
                                {[...areaFrom].sort().reverse().join(", ")}
                            </li>

                            { ordersCount > 0 &&
                                <li className="product-info-item">
                                    <span className="icon"><PackageCheck /></span>
                                    <span className="label">Заказов:</span> {ordersCount}
                                </li>
                            }

                            { saleQuantityInt > 0 &&
                                <li className="product-info-item">
                                    <span className="icon"><ShoppingCart  /></span>
                                    <span className="label">Продано:</span> {saleQuantityInt}+
                                </li>
                            }

                            {itemRepurchaseRate &&
                                <li className="product-info-item">
                                    <span className="icon"><RefreshCcw /></span>
                                    <span className="label">Повторные покупки:</span> {itemRepurchaseRate}
                                </li>
                            }

                        </ul>
                    </div>
                </div>
                <div className='footer'>
                    <div className='product-card_price-action'>
                        <div className='product-price'>
                            <div className='price'>
                                {price.toLocaleString('zh-CN', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: "CNY"
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="price-range-container">
                        {quantityPrices.map((priceRange, index) => (
                            <div key={index} className="price-range">
                                <p>{priceRange.end_num ? `${priceRange.begin_num} - ${priceRange.end_num.replace("件", "шт.")}` : `> ${priceRange.begin_num.replace("件", "шт.")}`}</p>
                                <p>{priceRange.price.toLocaleString('zh-CN', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: "CNY"
                                })}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ProductCard;
