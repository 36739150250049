import React from 'react'
import './ProductProps.less'

const ProductProps = ({productProps = []}) => {
    return(
        <div className='product-props'>
            {productProps.map((prop, index) =>{
                const key = Object.keys(prop)[0];
                return (
                    <div className="prop-item" key={index}>
                        <span className="prop-key">{key}:</span>
                        <span className="prop-value">{prop[key]}</span>
                    </div>
                );
            })}
        </div>
    )
}

export default ProductProps;
