import React from 'react'

import './CartTable.less';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {Icon} from "@iconify/react";
import CartProduct from "./CartProduct";
import {deleteCartItemRequestAsync} from "./cartSlice";


const CartTableHead = () => {
    return(
        <div className='cart-table-head'>
            <div className='remove' />
            <div className='product' >Наименование</div>
            <div className='price' >Цена</div>
            <div className='quantity' >Количество</div>
            {/*<div className='total' >Сумма</div>*/}
        </div>
    )
}

const CartTableLine = ({product={}}) => {
    const dispatch = useDispatch();
    const { productId} = product;

    return(
        <div key={productId} className='cart-table-line'>
            <div className='remove'>
                <Link onClick={() => dispatch(deleteCartItemRequestAsync(productId))} >
                    <Icon fontSize={16} icon='mdi:remove-bold' />
                </Link>
            </div>
            <div className='product'>
                <CartProduct product={product} />
            </div>
        </div>
    )
}


export const CartTable = () => {

    const products = useSelector(state => state.cart.items);

    return(
        <div className='cart-table'>
            {/*<CartTableHead />*/}
            <div className='cart-table-body'>
                {products.map(product => <CartTableLine product={product} /> )}
            </div>
        </div>
    )
}

