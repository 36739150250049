import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Image, Pagination, Skeleton, Flex, Divider, Row} from "antd";
// import ProductViewer from "../search/ProductViewer";
import {searchShopProductsAsync} from "./factory1688Slice";
import {useParams} from 'react-router-dom';

const ShopProducts = () => {
    const {memberId} = useParams();
    const dispatch = useDispatch();
    const productsResult = useSelector(state => state.factory1688.productsResult)
    const loading = useSelector(state => state.factory1688.productsLoading)
    const {sort} = productsResult;

    useEffect(()=>{
        dispatch(searchShopProductsAsync({memberId}));
    },[dispatch, memberId])

    const productsRenderer = () => {
        const {products} = productsResult;
        return (
            <></>
/*
            <Row gutter={[16, 16]} style={{ height: '100%' }}  justify="space-around">
                {products.map(product => (
                    <ProductViewer key={product.itemId} product={product} />
                ))}
            </Row>
*/
        )
    }

    const paginationRenderer = () => {
        const {pagination: { total = 0, pageSize = 20, current= 1}} = productsResult;
        if(total > pageSize) {
            return <Pagination
                // hideOnSinglePage={true}
                total={total}
                showTotal={(total, range) => `${range[0]}-${range[1]} из ${total}`}
                pageSize={pageSize}
                current={current}
                onChange={(page, pageSize) => {
                    const {memberId, pagination} = productsResult;
                    const newPagination = {
                        ...pagination,
                        current: page,
                    }

                    dispatch(searchShopProductsAsync({memberId, pagination: newPagination, sort}))
                }}
            />
        } else {
            return null;
        }
    }


    const contextRenderer = () => {
        if (!loading) {
            return (
                <Flex vertical className='f-products-content'>
                        {productsRenderer()}
                        <Divider />
                        {paginationRenderer()}
                </Flex>
            )
        } else {
            return (
                <Skeleton active/>
            )
        }
    }

    return(
        <Flex className='search-result-content'>
            {contextRenderer()}
        </Flex>
    )
}

export default ShopProducts;
