import React, {useEffect} from 'react'
import './ProductOriginalDetail.less'
import {useDispatch, useSelector} from "react-redux";
import {productDetailRequestAsync} from "./product1688Slice";

const ProductOriginalDetail = ({detailUrl=''}) => {
    const dispatch = useDispatch();
    const detail = useSelector(state => state.product1688.detailImgs)

    useEffect(()=>{
        dispatch(productDetailRequestAsync({detailUrl}));
    },[dispatch, detailUrl])

    return(
        <div style={{width: '100%'}}>
            {detail.map(url => <img src={url} alt="Описание товара" />)}
        </div>
    )
}

export default ProductOriginalDetail;
