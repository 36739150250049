import React, {useEffect, useRef} from 'react';

import './Product.less';
import {useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {productsRequestAsync, searchProductByUrl} from "./product1688Slice";

import {Skeleton} from "antd";
import ProductImageViewer from "./ProductImageViewer";
import ProductDetail from "./ProductDetail";
import ProductProps from "./ProductProps";
import ProductOriginalDetail from "./ProductOriginalDetail";


const Product = () => {
    const {productId} = useParams();
    const location = useLocation();
    const { productUrl } = location.state ?? {};
    const dispatch = useDispatch();

    const product = useSelector(state => state.product1688.product)
    const loading = useSelector(state => state.product1688.loading)

    useEffect(()=>{
        if (!productId && !productUrl) return;

        if(productId)
            dispatch(productsRequestAsync({productId}));
        if(productUrl)
            dispatch(searchProductByUrl({
                url: productUrl
            }));
    },[dispatch, productId, productUrl])

    if(loading)
        return <Skeleton active />

    return (
        <div className='product-page'>
            <div className='product-page-main d-grid grid-1 grid-md-2'>
                <div className='layout-left'>
                    <ProductImageViewer product={product}/>
                </div>
                <div className='layout-right'>
                    <ProductDetail product={product} />
                </div>
            </div>
            <div className='product-page_description-wrapper'>
                <ProductProps {...product} />
                <ProductOriginalDetail {...product}/>
            </div>
        </div>
    )
}

export default Product;
