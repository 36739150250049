import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Button, Image, Pagination, Skeleton, List, IconText} from "antd";
import './SearchResultContent.less'
import {searchFactoriesAsync, searchProductsAsync} from "./search1688Slice";
import FactoryViewer from "./FactoryViewer";
import SearchToolbar from "./common/SearchToolbar";

const SearchFactoryResultContent = () => {
    const dispatch = useDispatch();
    const factoriesResult = useSelector(state => state.search1688.factoriesResult)
    const loading = useSelector(state => state.search1688.loading)


    const factoriesRenderer = () => {
        const {factories} = factoriesResult;
        return factories.map(factory => {
            return (
                <FactoryViewer factory={factory}/>
            )
        });
    }

    const paginationRenderer = () => {
        const {pagination, keywords, originalKeywords, sort} = factoriesResult;
        const {total = 0, pageSize = 20, current= 0} = pagination;
        if(total>pageSize) {
            return <Pagination
                hideOnSinglePage={true}
                total={total}
                showTotal={(total, range) => `${range[0]}-${range[1]} из ${total}`}
                pageSize={pageSize}
                current={current}
                onChange={(page, pageSize) => {
                    const newPagination = {
                        ...pagination,
                        current: page,
                    }

                    dispatch(searchFactoriesAsync({keywords, originalKeywords, pagination: newPagination, sort}))
                }}
            />
        }
    }

    const contextRenderer = () => {
        if (!loading) {
            return (
                <>
                    <SearchToolbar />
                    <div className='factories-content'>{factoriesRenderer()}</div>
                    <div className='products-content-footer'>
                        {paginationRenderer()}
                    </div>
                </>
            )
        } else {
            return (
                <Skeleton active/>
            )
        }
    }

    return(
        <div className='search-result-content'>
            {contextRenderer()}
        </div>
    )
}

export default SearchFactoryResultContent;
