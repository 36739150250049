import React, {useState, useRef} from 'react'
import {Button, Flex, Input, Radio, Cascader, Select, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {
    searchFactoriesAsync,
    searchProductsAsync,
    setSearchType,
    uploadImageAsync
} from "../1688/search/search1688Slice";
import {FactoriesSortTypes, ProductsSortTypes, SearchTypes} from "../../constants/enums";
import {useNavigate} from "react-router-dom";
import { sendMetric } from "../../utils/metrics";

import './SearchController.less'
import {Icon} from "@iconify/react";
import { Camera } from "lucide-react";

const { Search } = Input;
const { Text } = Typography;

const SearchController = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fileInputRef = useRef(null)

    const productsResult = useSelector(state => state.search1688.productsResult)
    const [productKeywords, setProductKeywords] = useState(productsResult.keywords);
    const [originalProductKeywords, setOriginalProductKeywords] = useState(productsResult.originalKeywords);
    const [productSort, setProductSort] = useState(productsResult.sort);

    const factoriesResult = useSelector(state => state.search1688.factoriesResult)
    const [factoryKeywords, setFactoryKeywords] = useState(factoriesResult.keywords);
    const [originalFactoryKeywords, setOriginalFactoryKeywords] = useState(factoriesResult.originalKeywords);
    const [factorySort, setFactorySort] = useState(factoriesResult.sort);

    const searchType = useSelector(state => state.search1688.searchType)

    const options = [
        {
            value: SearchTypes.PRODUCTS,
            label: 'Товар',
        },
        {
            value: SearchTypes.FACTORIES,
            label: 'Фабрика',
        }
    ]

    const handleChange = (value) => {
        dispatch(setSearchType(value))
    };

    const handleSearch = () => {
        const regex = /^https?:\/\/(detail\.)?1688\.com(\/.*)?$/i;
        if(regex.test(productKeywords)){ //если это ссылка на страницу товара на 1688.com
            navigate("/1688/products1688byUrl", { state: { productUrl : productKeywords}})
        } else {
            dispatch(searchProductsAsync({
                keywords: productKeywords,
                originalKeywords: undefined,
                pagination: undefined,
                sort: productSort
            }));
            navigate("/1688/search")
        }
    };

    const handleClick = () => {
        fileInputRef.current.value = "";
        fileInputRef.current.click();
    };
    const handleUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;
        dispatch(uploadImageAsync({file}));
        navigate("/1688/search");
    };

    const prefix = (
        <Select
            placeholder="Поиск товара или фабрики по товару"
            style={{ width: 150 }}
            value={searchType}
            options={options}
            onChange={handleChange}
        />
    );

    const productSearchRenderer = () => {
        const {originalKeywords} = productsResult;
        return(
            <Flex vertical gap="small" align="left" >
                <Search className='search-editor-wrapper'
                        suffix= {
                            <Camera className='upload-icon' onClick={() => {
                                sendMetric('reachGoal', 'SearchProductButtonClick');
                                handleClick();
                            }}/>
                        }
                        placeholder="Наберите наименование товара или вставьте ссылку на страницу товара на 1688.com"
                        enterButton=<Icon fontSize={28} icon='ic:round-search' />
                        size="large"
                        addonBefore={prefix}
                        onSearch={(e) => {
                            sendMetric('reachGoal','SearchProductButtonClick');
                            handleSearch();
                            }}
                        value={productKeywords}
                        onChange={(e) => {
                            setProductKeywords(e.target.value)
                            setOriginalProductKeywords(undefined);
                        }}
                />
                <Text className='original-request'><span>оригинальный запрос: </span>{originalKeywords}</Text>

                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleUpload}
                    accept="image/*"
                    style={{ display: "none" }}
                />
            </Flex>
        )
    }

    const factorySearchRenderer = () => {
        const {originalKeywords} = factoriesResult;
        return(
            <Flex vertical gap="small" align="left" className='search-editor-wrapper'>
                    <Search className='search-editor-wrapper'
                        placeholder="Наберите наименование товара"
                        enterButton=<Icon fontSize={28} icon='ic:round-search' />
                        size="large"
                        addonBefore={prefix}
                        onSearch={(e) => {
                            sendMetric('reachGoal','FactorySearchButtonClick');
                            dispatch(searchFactoriesAsync({
                                keywords: factoryKeywords,
                                originalKeywords: undefined,
                                pagination: undefined,
                                sort: factorySort
                            }));
                            navigate("/1688/search")}
                        }
                        value={factoryKeywords}
                        onChange={(e) => {
                            setFactoryKeywords(e.target.value)
                            setOriginalFactoryKeywords(undefined);
                        }}
                    />
                <Text className='original-request'><span>оригинальный запрос: </span>{originalKeywords}</Text>
            </Flex>

        )
    }

    const searchRenderer = () => {
        switch (searchType) {
            case SearchTypes.PRODUCTS: return  productSearchRenderer();
            case SearchTypes.FACTORIES: return factorySearchRenderer();
            default: productSearchRenderer();
        }
    }

    return(
        <Flex vertical className='header-search'>
            {searchRenderer()}
        </Flex>
    )
}

export default SearchController;
