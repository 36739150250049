import React from "react";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import "./StarRating.less"; // Подключаем стили

const StarRating = ({ score }) => {
    const maxStars = 5;
    const fullStars = Math.floor(score); // Полные звезды
    const hasHalfStar = score % 1 >= 0.5; // Проверка на половину звезды
    const emptyStars = maxStars - fullStars - (hasHalfStar ? 1 : 0); // Пустые звезды

    return (
        <div className="star-rating">
            {/* Полные звезды */}
            {Array(fullStars).fill().map((_, i) => (
                <FaStar key={`full-${i}`} className="star full" />
            ))}

            {/* Половинка звезды (если есть) */}
            {hasHalfStar && <FaStarHalfAlt key="half" className="star half" />}

            {/* Пустые звезды */}
            {Array(emptyStars).fill().map((_, i) => (
                <FaRegStar key={`empty-${i}`} className="star empty" />
            ))}
        </div>
    );
};

export default StarRating;
