import React, {useState} from 'react'
import {Button, Flex, Input, Radio, Cascader, Select, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import { AudioOutlined } from '@ant-design/icons';
import {searchFactoriesAsync, searchProductsAsync, setSearchType} from "../search1688Slice";
import {FactoriesSortTypes, ProductsSortTypes, SearchTypes} from "../../../../constants/enums";

import './SearchToolbar.less'
const { Search } = Input;
const { Text } = Typography;

const SearchToolbar = () => {
    const dispatch = useDispatch();

    const productsResult = useSelector(state => state.search1688.productsResult)
    const [productKeywords, setProductKeywords] = useState(productsResult.keywords);
    const [originalProductKeywords, setOriginalProductKeywords] = useState(productsResult.originalKeywords);
    const [productSort, setProductSort] = useState(productsResult.sort);

    const factoriesResult = useSelector(state => state.search1688.factoriesResult)
    const [factoryKeywords, setFactoryKeywords] = useState(factoriesResult.keywords);
    const [originalFactoryKeywords, setOriginalFactoryKeywords] = useState(factoriesResult.originalKeywords);
    const [factorySort, setFactorySort] = useState(factoriesResult.sort);

    const searchType = useSelector(state => state.search1688.searchType)

    const productSorts = () => {
        const {pagination: { total = 0}} = productsResult;
        if(total > 0) {
            return (
                <Select
                    prefix="Сортировка"
                    name="productSort"
                    size="default"
                    value={productSort}
                    onChange={(e) => {
                        setProductSort(e);
                        if(productKeywords) {
                            dispatch(searchProductsAsync({
                                keywords: productKeywords,
                                originalKeywords: originalProductKeywords,
                                pagination: undefined,
                                sort: e
                            }))
                        }
                    }}
                >
                    <Select.Option value={ProductsSortTypes.DEFAULT}>по умолчанию</Select.Option>
                    <Select.Option value={ProductsSortTypes.SALES}>по популярности</Select.Option>
                    <Select.Option value={ProductsSortTypes.PRICE_DOWN}>дороже</Select.Option>
                    <Select.Option value={ProductsSortTypes.PRICE_UP}>дешевле</Select.Option>
                </Select>
            )
        }
    }

    const factorySorts = () => {
        const {pagination: { total = 0}} = factoriesResult;
        if(total > 0) {
            return (
                <Select
                    name="factorySort"
                    size="default"
                    value={factorySort}
                    onChange={(e) => {
                        setFactorySort(e);
                                if(factoryKeywords) {
                                    dispatch(searchFactoriesAsync({
                                        keywords: factoryKeywords,
                                        originalKeywords: originalFactoryKeywords,
                                        pagination: undefined,
                                        sort: e
                                    }))
                                }
                    }}
                >
                    <Select.Option value={FactoriesSortTypes.DEFAULT}>по умолчанию</Select.Option>
                    <Select.Option value={FactoriesSortTypes.REPURCHASE_RATE}>по рейтингу повтр. заказов</Select.Option>
                </Select>
            )
        }
    }

    const sortRenderer = () => {
        switch (searchType) {
            case SearchTypes.PRODUCTS: return productSorts();
            case SearchTypes.FACTORIES: return factorySorts();
            default: productSorts();
        }
    }

    return(
        <Flex vertical className='search-toolbar-wrapper justify-content-between'>
            <div className="collection-view-as">
            </div>
            <div className='search-sorting'>
                {sortRenderer()}
            </div>
        </Flex>
    )
}

export default SearchToolbar;
