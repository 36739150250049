import React, {useEffect} from 'react'
import CartProductProps from "./CartProductProps";

import './CartProduct.less'
import {Link} from "react-router-dom";


const CartProduct = ({product = {}}) => {
    const { productId, productDetail = {}, orderedSkus = []} = product;
    const fabricusUrl = `/1688/products/${productId}`;
    const {title, originalTitle, productUrl, mainImgs = []} = productDetail;
    const img = mainImgs[0];

    return(
        <div key={productId} className='cart-product'>
            <div className='cart-product-desc'>
                <Link className='product-img'  to={fabricusUrl} target='_blank'  >
                    <img src={img} />
                </Link>
                <div  className='cart-product-container'>
                    <h1>{title}</h1>
                </div>
            </div>
            <CartProductProps product={product} />
        </div>
    );
}

export default CartProduct;
