import React, {createContext, useState, useEffect, useContext} from 'react';

// Создаем контекст
const WindowWidthContext = createContext();

// Компонент, который будет предоставлять данные контекста
export const WindowWidthProvider = ({ children }) => {
    // const [width, setWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    // Функция для обновления ширины экрана
    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    // Отслеживаем изменения ширины экрана
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Убираем обработчик события при размонтировании компонента
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <WindowWidthContext.Provider value={isMobile}>
            {children}
        </WindowWidthContext.Provider>
    );
};

export const useWindowWidth = () => {
    return useContext(WindowWidthContext);
};
