import React, {useEffect, useState} from 'react';
import {Breadcrumb, Layout, Radio, Space} from "antd";
import {Helmet} from 'react-helmet-async';
import './HomePage.less'
import {useDispatch} from "react-redux";
import {Link, Outlet, useNavigate, useLocation} from "react-router-dom";
import HomeHeaderDesk from "./HomeHeaderDesk";
import HomeFooter from "./HomeFooter";
import {Container} from '../../components'
import {Icon} from "@iconify/react";
import {FBreadcrumb} from "../../components/FBreadcrumb";
import {useWindowWidth} from "../../components/context/WindowWidthProvider";
import HomeHeaderMobile from "./HomeHeaderMobile";
import PlatformDescription from "../1688/search/PlatformDescription"

const {Header, Content, Footer, Sider} = Layout;


const initHelmet = {
    isRoot: false,
    description: 'Поиск производителей для селлеров маркетплейсов',
    title: 'Fabrius'
};


export const HomePage = (props) => {
    const [helmet] = useState(initHelmet);
    const [title, setTitle] = useState(helmet.title);
    const isMobile = useWindowWidth();

    const dispatch = useDispatch();
    // const isAuthenticated = useSelector(selectIsAuthenticated);

    useEffect(() => {
        if (helmet.isRoot) {
            setTitle(`${helmet.title}`);
        } else {
            setTitle(`${helmet.title} | Поиск товаров для маркетплейса`);
        }
    }, [helmet]);



    return (
        <Layout className="body-main-content">
            <Helmet>
                <html lang="en"/>
                <title>{title}</title>
                <meta name="description" content={helmet.description}/>
            </Helmet>


            { !isMobile && 
                <Header  className="header-group">
                    <HomeHeaderDesk />
                </Header> 
            }

            { isMobile && 
                <Header  className="header-group" style={{ position: 'sticky', zIndex: 1}}>
                    <HomeHeaderMobile />
                </Header> 
            }

            <Layout className='main-layout'>
                <Content className='main-layout-content' >
                    <Container>
                        <Outlet />
                    </Container>
                </Content>
            </Layout>
            <Footer className='footer'>
                <HomeFooter />
            </Footer>
        </Layout>
    );
};
