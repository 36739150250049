import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useAuth} from "../../../auth/useAuth";
import {Link} from "react-router-dom";
import {InputNumber} from "antd";
import {addCartItemRequestAsync, deleteCartItemPropRequestAsync, updateCartBySkuRequestAsync} from "./cartSlice";
import {Icon} from "@iconify/react";

import './CartProductProps.less';
import {FInputNumber} from "../../../components/FInputNumber";



const CartSkuAmn = ({productId, provider, sku = {}}) => {
    const dispatch = useDispatch();
    const {propsNames = '', orderedCount = 0, skuid, specid, propsIds} = sku;
    const [orderedAmn, setOrderedAmn] = useState(orderedCount);


    const saveRenderer = () => {
        if ( orderedAmn && orderedAmn !== orderedCount) {
        return(
            <div className="save">
                <Link onClick={() =>
                    dispatch(updateCartBySkuRequestAsync({itemId: productId,
                        api : provider, skuid, specid, propsIds, orderedCount: orderedAmn}))
                }>
                    <Icon fontSize={26} icon='tabler:shopping-cart-down' />
                </Link>
            </div>
        )}
    }

    return(
        <div className='sku-prop-amn'>
            <div className="prop-title">{propsNames.split(';').slice(1).map(name => name)}</div>
            <div className="amn">
                <InputNumber size="small" min={0} max={100000}
                             value={orderedAmn}
                             onChange={value => setOrderedAmn(value)}/>
            </div>
            {saveRenderer()}
        </div>
    )
}

const PropsLines = ({productId, skuProps, props}) => {
    const dispatch = useDispatch();

    const currency='CNY';
    return (
        props.map(prop => {
            const {img, pid, name, ordered} = prop;
            return (
                <div key={pid} className='sku-group-table-line'>
                    <div className='image'>
                        {img && <img src={img} alt=''/>}
                    </div>
                    <div className='first-prop-name'>
                        {name}
                    </div>
                    <div className='other'>
                        {
                            ordered.map(({skuid, specid, propsNames, orderedCount, salePrice, propsIds}) => {
                                const names = propsNames.split(';').slice(1);
                                return (
                                    <div key={specid} className='sku-prop-table-line'>
                                        <div className='prop-names'>
                                            {names.map(name => <div>{name}</div>)}
                                        </div>
                                        <div className='quantity'>
                                            <FInputNumber
                                                value={orderedCount}
                                                onChange={(value) => dispatch(
                                                    updateCartBySkuRequestAsync({productId, api: 'TMAPI', skuid, specid, propsIds, orderedCount: value})
                                                    )}
                                            />
                                        </div>
                                        <div className='price'>{salePrice}</div>
                                        <div className='total'>{(orderedCount * salePrice).toLocaleString('zh-CN', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                            style: 'currency',
                                            currency: currency
                                        })}</div>
                                        <div className='remove'>
                                            <Link onClick={() => dispatch(deleteCartItemPropRequestAsync({ productId,skuid}))}>
                                                <Icon fontSize={16} icon='mdi:remove-bold' />
                                            </Link>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )
        })
    )
}



const CartProductProps = ({product ={}}) => {
    const {productDetail = {}, orderedSkus = [], productId, provider} = product;
    const {skuProps =[], skus = [], currency='CNY', itemId} = productDetail;
    const { isCustomer = () => {}} = useAuth();

    const skuByProp = (propsIds) => {
        return skus.find(sku=> sku.props_ids === propsIds );
    }

    const skuBySpecid = (specid) => {
        return skus.find(sku=> sku.specid === specid );
    }

    const orderedProps = () => {
        const {productDetail = {}, orderedSkus = [], productId} = product;
        const {skuProps =[], skus = [], currency='CNY', itemId} = productDetail;

        //обогащение наименованием и фото свойств skuProps
        //первая группа
        const {pid, values} = skuProps[0];
        const richValues = values.map( skuPropValue => {
            const {vid, name, imageUrl} = skuPropValue;
            const filteredOrdSkus = orderedSkus.filter(pos => {
                const {propsIds} = pos;
                const ids = propsIds.split(';');
                const pidProps = ids.find( props => {
                    if ( props.split(':')[0] ? props.split(':')[0] === pid.toString() : false)
                        return props;
                });

                if(pidProps && pidProps.split(':')[1] === vid.toString())
                    return true;
                else
                    return false;
            })
            .sort((a, b) => a.propsIds < b.propsIds ? -1 : 1);

            return {
                pid,
                name,
                img : imageUrl,
                ordered : filteredOrdSkus
            }
        }).filter( ({ordered=[]}) => ordered.length > 0);

        return {
            productId,
            skuProps,
            props : richValues
        }
    }

    const orderedSkusRenderer = () => {
        if(skuProps.length > 0) {
            return(
                <div className='sku-prop-table'>
                    <div className='sku-prop-table-head'>
                        <div className='image' />
                        <div className='prop-names' >
                            {skuProps.map(({prop_name = ''}) => {
                                const firstLetterCap = prop_name.charAt(0).toUpperCase();
                                const remainingLetters = prop_name.slice(1);
                                return <div>{firstLetterCap + remainingLetters}</div>
                            })}
                        </div>
                        <div className='quantity'>Количество</div>
                        <div className='price'>Цена</div>
                        <div className='total'>Сумма</div>
                        <div className='remove' />
                    </div>
                    {
                        <PropsLines {...orderedProps()} />
                    }
                </div>
            )
        }
    }

    return (
        <div>
            {orderedSkusRenderer()}
        </div>
    )
}

export default CartProductProps;
