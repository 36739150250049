import { Button, Flex, Layout } from 'antd';
import PlatformDescription from './PlatformDescription'
import './Landing.less'
import '../../home/HomePage.less'
import {useNavigate} from "react-router-dom";
import { sendMetric } from "../../../utils/metrics";

const { Content, Footer} = Layout;

const Landing = () => {
  const navigate = useNavigate();
  const onSearchButtonClick = async (values) => {
    sendMetric('reachGoal','StartSearchButtonClick');
    navigate('/1688/search');
  }

  return (
          <Layout className="body-main-content">
              <Layout className='main-layout'>
                  <Content className='main-layout-content' >
                  <Flex vertical>
                    <PlatformDescription />
                    <div className="footer-section">
                      <Button size='large' className="footer-section-button" onClick={onSearchButtonClick}>
                        Начать поиск
                      </Button>
                    </div>
                  </Flex>
                  </Content>
              </Layout>
          </Layout>
      );
}

export default Landing;