export const SearchTypes = { PRODUCTS: 'products', FACTORIES: 'factories' };
export const ProductsSortTypes = {
    DEFAULT: 'default',
    SALES: 'sales',
    PRICE_UP: 'price_up',
    PRICE_DOWN: 'price_down'
};

export const FactoriesSortTypes = {
    DEFAULT: 'default',
    REPURCHASE_RATE: 'repurchase_rate'
};

export const createOrderStatus = { NONE: 'NONE', CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS', CREATE_ORDER_FAIL: 'CREATE_ORDER_FAIL' };

export const OrderStatusNames = {
    NEW: 'Новый',
    IN_PROCEED_BY_TC: 'На рассмотрении ТК',
    ACCEPTED_BY_TC: 'Согласовано ТК',
    REJECTED_BY_TC : 'Отклонено ТК',
    ACCEPTED_BY_CUSTOMER: 'Согласовано клиентом',
    REJECTED_BY_CUSTOMER: 'Отклонено клиентом',
    IN_DELIVERY: 'В доставке',
    DONE: 'Выполнено'
}

export const OrderPaymentStatusNames = {
    NONE: 'Не оплачен',
    DONE: 'Оплачен'
}

export const OrdersSortTypes = {
    DEFAULT: 'default'
}

