import React, {useEffect} from 'react'
import './HomeHeaderDesk.less'
import {  Flex, Typography, Divider, Space, Row, Col, Button } from 'antd';
import logo from '../../logo.png';
import { Link , useNavigate, useLocation} from 'react-router-dom';
import HeaderActionList from "./HeaderActionList";
import SearchController from './SearchController';
import {Icon} from "@iconify/react";
import {Container} from "../../components";
import {useAuth} from "../../auth/useAuth";

const HomeHeaderDesk = () => {
    const { isAuthenticated = () => { }, isCustomer = () => { }, isTC = () => { }, codeRequestUrl = () => { }, tokenRevoke = () => { }, user = {} } = useAuth();
    const {userName} = user;

    const handleLogin = async () => {
        try {
            const url = await codeRequestUrl();
            window.location.replace(url);
        }
        catch (err) {
            console.log('Ohhhh nooo!');
            console.log(err);
            tokenRevoke();
        }
    }

    const handleLogout = async () => {
        tokenRevoke();
    }

    const authRenderer = () => {
        if (!isAuthenticated()) {
            return (
                <Link to={'/account/login'}>
                    <span className='icon'>
                        <Icon fontSize={16} icon='lucide:user-round' />
                    </span>
                    <span>Вход / Регистрация</span>
                </Link>
            )
        } else {
            return(
                <Link onClick={() => handleLogout()}>
                    <span className='icon'>
                        <Icon fontSize={16} icon='lucide:user-round' />
                    </span>
                    <span>{userName}</span>
                </Link>
            )
        }
    }


    return(
        <>
            <div className='topbar-container'>
                <Container>
                    <Flex justify='space-between' className='topbar-wrapper'>
                        <Flex className='topbar-left'>
                            <ul className="topbar-items">
                                <li className="tb-item">
                                    <span className='icon'>
                                        <Icon fontSize={16} icon='ic:outline-email' />
                                    </span>
                                    <span>info@fabrius.ru</span>
                                </li>
                            </ul>
                        </Flex>
                        <Flex className='topbar-right'>
                            <ul className="topbar-items">
                                <li className="tb-item">
                                    <span>
                                        ¥
                                    </span>
                                </li>
                                <Divider type={"vertical"} />
                                <li className="tb-item">
                                    <span className='icon'>
                                        <Icon fontSize={16} icon='tabler:world' />
                                    </span>
                                    <span>
                                        РУС
                                    </span>
                                </li>
                                <Divider type={"vertical"} />
                                <li className="tb-item">
                                    {authRenderer()}
                                </li>
                            </ul>
                        </Flex>
                    </Flex>
                </Container>
            </div>
            <div className='header-container'>
                <div className='header-wrapper'>
                    <div className='header-top'>
                        <Container>
                            <Flex justify='space-between' className='header-top-wrapper'>
                                <div className='header-logo'>
                                    <Link to={`/`}>
                                        <img src='/logo.png' alt='logo' width='100%' />
                                    </Link>
                                </div>
                                <div className='header-search-menu'>
                                    <SearchController />
                                </div>
                                <HeaderActionList />
                            </Flex>
                        </Container>
                    </div>
                </div>
            </div>
            <div className='header-departments'>
                <Container>
                    <div className='section-wrapper'>
                        <ul className='hd-list'>
                            <li>
                                <img src='/1688.png' alt='1688.com' width='22' height='22' />
                                <span>1688.com</span>
                            </li>
                        </ul>
                    </div>
                </Container>
            </div>
        </>
    )}

export default HomeHeaderDesk;
