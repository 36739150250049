import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Button, InputNumber, message, notification, Space} from "antd";
import {Link} from "react-router-dom";
import {Icon} from "@iconify/react";
import {useAuth} from "../../../auth/useAuth";
import {setCurrentImage, setCurrentProp} from "./product1688Slice";
import {sendMetric} from "../../../utils/metrics";
import {addCartItemRequestAsync, cartRequestAsync, clearCartRequestAsync} from "../../cabinet/cart/cartSlice";
import { ShoppingCart } from "lucide-react";

import './ProductSkus.less';
import {FInputNumber} from "../../../components/FInputNumber";


const ImageProp = ({prop, setCurrentData = (imageUrl, pid, vid) =>{}}) => {
    const currentProps = useSelector(state => state.product1688.currentProps);

    const {prop_name, pid, values = []} = prop;
    const currentProp = currentProps[pid];



    return(
        <div className='prop-item-wrapper' key={pid}>
            <h3 className='prop-title'>{prop_name}</h3>
            <div className='prop-img-wrapper'>
                {values.map((val, index) => {
                    const {name, vid, imageUrl} = val;
                    const isActive = currentProp === vid;

                    return(
                        <div className={isActive ? 'prop-img active' : 'prop-img'} key={vid}
                             onClick={()=> setCurrentData(imageUrl, pid, vid)}>
                            { imageUrl && <img src={imageUrl} alt=''
                                               onClick={()=>{
                                                   setCurrentData(imageUrl, pid, vid);
                                               }}
                            /> }
                            <div className='prop-name'>{name}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const AmnProp = ({prop, currency, skus = [], orderedSkus = [],
                     setOrderedSkus = () => {},
                     setCurrentData = (imageUrl, pid, vid) =>{}}
    ) => {
    const {prop_name, pid, values = []} = prop;
    const currentProps = useSelector(state => state.product1688.currentProps);

    const currentPropValue = () => {
        return Object.entries(currentProps).map(( value) => {
            return value[0] + ':' + value[1];
        }).join(';');
    }
    const propValues = currentPropValue();

    const skuByProp = (propsIds) => {
        return skus.find(sku=> sku.props_ids === propsIds );
    }

    return(
        <div className='prop-item-wrapper' key={pid}>
            <h3 className='prop-title'>{prop_name}</h3>
            <div className='prop-data-wrapper'>
                {values.map((val,idex)=>{
                    const {name, vid, imageUrl} = val;
                    const propsIds =
                        propValues ? propValues + ';' + pid + ':' + vid
                            : pid + ':' + vid;
                    const sku = skuByProp(propsIds);
                    if(sku) {
                        const {skuid, specid, sale_price = 0, original_price = 0, stock} = sku;
                        const price = sale_price ? sale_price : original_price;

                        return (
                            <div className='sku-item-wrapper' key={vid}>
                                <div className='name'>
                                    {imageUrl &&
                                        <img src={imageUrl} alt='' onClick={()=>{
                                            setCurrentData(imageUrl);
                                        }}/>}
                                    <span>{name}</span>
                                </div>
                                <div className='price'>{price.toLocaleString('zh-CN', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: currency
                                })}</div>
                                <div className={`stock ${stock > 0 ? 'in-stock' : ''}`}>
                                    {stock > 0 ? 'в наличии' : 'нет на складе'}
                                </div>
                                <div className='amount'>
                                    <FInputNumber
                                        value={orderedSkus[skuid]}
                                        onChange={ (value) => {setOrderedSkus({...orderedSkus, [skuid]: value})}}
                                    />
                                </div>
                            </div>
                        )
                    }
                    return <></>
                })}
            </div>
        </div>
    )
}

const ButtonPanel = ({ orderedSku={}, saveToCard = () => {}}) => {
    const cnt = Object.values(orderedSku).reduce((acc, value ) => acc += value, 0);

    return(
        <div className='buttons-panel-wrapper'>
            <Button disabled={cnt===0} type='primary' className='btn btn-1 btn-sku' /*icon={<ShoppingCart/>}*/
                    onClick={() => saveToCard()}>
                Сохранить в корзине
            </Button>
        </div>
    )
}

const ProductSkus = ({product ={}}) => {
    const { isCustomer = () => {}} = useAuth();

    const dispatch = useDispatch();
    // const currentProps = useSelector(state => state.product1688.currentProps);
    const cartProducts = useSelector(state => state.cart.items);
    const { addItemStatus, addItemError } = useSelector(state => state.cart);

    const [itemAdding, setItemAdding] = useState(false);

    const setCurrentData = (imageUrl, pid, vid) => {
        if (imageUrl)
            dispatch(setCurrentImage(imageUrl));
        console.log(pid + " " + vid);
        dispatch(setCurrentProp({pid: pid, vid: vid}));
    }

    useEffect(() => {
        if (itemAdding){
            if (addItemStatus === 'loading') {
                message.loading({ content: 'Добавление в корзину...', key: 'loading' });
            } else if (addItemStatus === 'succeeded') {
                message.success({ content: 'Товар добавлен в корзину!', key: 'loading', duration: 2 });
                setItemAdding(false);
            } else if (addItemStatus === 'failed') {
                message.error({ content: `Ошибка добавления товара: ${addItemError}`, key: 'loading', duration: 2 });
                setItemAdding(false);
            }
        }
    }, [itemAdding, addItemStatus, addItemError]);

    const {skuProps =[], skus = [], currency='CNY', itemId} = product;

    const cartOrderedSkus = () => {
        const orderedCartProduct = cartProducts.reduce((acc, item) => item.productId === itemId ? item: acc, {orderedSkus: []});
        const {orderedSkus : orderedCartProductSkus} = orderedCartProduct;
        return orderedCartProductSkus.reduce((acc, item) =>
        {
            acc[item.skuid] = item.orderedCount;
            return acc;
        }, {})
    }

    const [orderedSkus, setOrderedSkus] = useState(cartOrderedSkus());

    useEffect(()=>{
        setOrderedSkus(cartOrderedSkus())
    },[cartProducts]);


    return (
        <div className='product-props-wrapper'>{
            skuProps.map((prop, index) => {
                if(index !== skuProps.length-1)
                    return <ImageProp
                        prop={prop}
                        setCurrentData={setCurrentData}/>;
                else
                    return <AmnProp prop={prop}
                                    skus={skus}
                                    currency={currency}
                                    orderedSkus={orderedSkus}
                                    setCurrentData={setCurrentData}
                                    setOrderedSkus={setOrderedSkus}
                    />;
            })
        }
            <ButtonPanel
                orderedSku={orderedSkus}
                saveToCard = {() => {
                    if(!isCustomer())
                        notification.warning({
                            message: 'Внимание',
                            description: 'Для добавления в корзину необходима авторизация в системе.',
                            icon: <Icon icon='ant-design:exclamation-circle-outlined'  style={{ color: '#faad14' }} />,
                        });
                    else {
                        const ord = Object.entries(orderedSkus).map(item => {
                            return {
                                skuid: item[0],
                                orderedCount: item[1]
                            };
                        })
                        dispatch(addCartItemRequestAsync(
                            {productId: itemId, orderedSkus: ord}
                        ));
                    }
                }}
            />
        </div>
    )

}


export default ProductSkus;
