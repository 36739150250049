import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Button, Image, Pagination, Skeleton, Flex, Divider, Row} from "antd";
import './SearchResultContent.less'
import {searchProductsAsync} from "./search1688Slice";
import SearchToolbar from "./common/SearchToolbar";
import ProductCollection from "./product/ProductCollection";
import {useWindowWidth} from "../../../components/context/WindowWidthProvider";

const SearchProductResultContent = () => {
    const isMobile = useWindowWidth();

    const dispatch = useDispatch();
    const productsResult = useSelector(state => state.search1688.productsResult)
    const loading = useSelector(state => state.search1688.loading)
    const {sort} = productsResult;
    const {pagination: { total = 0, pageSize = 20, current= 1}} = productsResult;
    const isNotEmpty = total > pageSize

    const paginationRenderer = () => {
        if (isNotEmpty) {
            if (isMobile) {
                return (
                    <div className='f-products-content_footer'>
                        <Pagination
                            // hideOnSinglePage={true}
                            total={total}
                            pageSize={pageSize}
                            current={current}
                            onChange={(page, pageSize) => {
                                const {keywords, originalKeywords, pagination} = productsResult;
                                const newPagination = {
                                    ...pagination,
                                    current: page,
                                }
                                dispatch(searchProductsAsync({
                                    keywords,
                                    originalKeywords,
                                    pagination: newPagination,
                                    sort
                                }))
                            }}
                        />
                    </div>
                )
            }
            else {
                return (
                    <div className='f-products-content_footer'>
                        <Pagination
                            // hideOnSinglePage={true}
                            total={total}
                            showTotal={(total, range) => `${range[0]} -${range[1]} из ${total}`}
                            pageSize={pageSize}
                            current={current}
                            onChange={(page, pageSize) => {
                                const {keywords, originalKeywords, pagination} = productsResult;
                                const newPagination = {
                                    ...pagination,
                                    current: page,
                                }
                                dispatch(searchProductsAsync({keywords, originalKeywords, pagination: newPagination, sort}))
                            }}
                        />
                    </div>
                )
            }
        }
        else {
            return null;
        }
    }


    const contextRenderer = () => {
        if (!loading) {
            if(isNotEmpty)
                return (
                    <Flex vertical className='f-products-content'>
                        <SearchToolbar />
                        <ProductCollection />
                        {paginationRenderer()}
                    </Flex>
                )
        } else {
            return (
                <Skeleton active/>
            )
        }
    }

    return(
        <Flex className='search-result-content grid-1'>
            {contextRenderer()}
        </Flex>
    )
}

export default SearchProductResultContent;
