import React from 'react'
import './ProductPrice.less'

const ProductPrice = ({product}) => {
    const {priceInfo = {}, skuPriceRange = {}, currency= 'CNY'} = product;
    const {sku_param = [], begin_num} = skuPriceRange;
    const {price, price_min = 0, price_max=0} = priceInfo;


    const priceRenderer = () => {
        if(sku_param.length > 0) {
            return (
                <div className="product_price">
                    {sku_param.map((priceRange, index) => {
                        const {price = 0, beginAmount=0} = priceRange;
                        return <div key={index} className="price-range">
                            <div className='price'>{price.toLocaleString('zh-CN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                                style: 'currency',
                                currency: currency
                            })}</div>
                            <div className='amount'>{beginAmount}</div>
                        </div>
                    })}
                </div>
            )
        }
        else
            return (
                <div className="product_price">
                    <div className="price-range">
                        <div className='price'>
                            <div>
                                {price_min.toLocaleString('zh-CN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                                style: 'currency',
                                currency: currency
                            })}
                            </div>
                            <span>~</span>
                            <div>
                                {price_max.toLocaleString('zh-CN', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: currency
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )

    }

    return(
        <div className='product_price-wrapper'>
            <h3 className='prop-title'>
                Цена
            </h3>
            {priceRenderer()}
        </div>
    )
}

export default ProductPrice;
