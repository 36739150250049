import React from 'react';
import {Outlet} from "react-router-dom";


const Page1688 = () => {
    return(
        <Outlet />
    );
}

export default Page1688;
