import './App.less';
import {HelmetProvider} from "react-helmet-async";
import {App as AntApp, ConfigProvider} from "antd";
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import {HomePage} from "./features/home/HomePage";
import Page1688 from "./features/1688/Page1688";
import Search1688Content from "./features/1688/search/Search1688Content";
import Product from "./features/1688/products/Product";
// import Products1688byUrl from "./features/1688/products/Products1688byUrl";
import Factories1688 from "./features/1688/factories/Factories1688";
import Cabinet from './features/cabinet/Cabinet';
import Account from './features/account/Account';
import LoginPage from './features/account/LoginPage';
import OrdersPage from './features/cabinet/order/OrdersPage';
import OrderView from './features/cabinet/tcOrders/OrderView';

import {AuthProvider} from "./auth/useAuth";
import AuthCallback from "./auth/AuthCallback";
import Cart from "./features/cabinet/cart/Cart";
import CreateOrderSuccess from "./features/cabinet/cart/CreateOrderSuccess";
import Profile from "./features/cabinet/profile/Profile";
import RegisterPage from "./features/account/RegisterPage";
import LocationViewer from "./components/LocationViewer";
import {WindowWidthProvider} from "./components/context/WindowWidthProvider";
import Landing from "./features/1688/search/Landing"
import Description from "./features/1688/search/PlatformDescription.js"

const App = (props) => {

    return (
        <AuthProvider>
            <HelmetProvider>
                <WindowWidthProvider>
                    <AntApp>
                        <ConfigProvider
                            theme={{
                                token: {
                                    fontFamily: "'Open Sans', sans-serif",
                                    fontSize: 14,
                                    topbarBgColor: '#2a455d',
                                    colorText: '#333e48',
                                },
                                components: {
                                    Layout: {
                                        fontFamily: "'Open Sans', sans-serif",
                                        colorBgLayout: '#f7f7f7',
                                        footerBg: '#F2F5F9',
                                        siderBg: '#fff',
                                        headerBg: '#fff',
                                        headerHeight: 'normal',
                                    },
                                    Menu: {
                                        horizontalItemSelectedColor: '#316593',
                                        horizontalLineHeight: 0,
                                        radiusItem: 0,
                                        itemBorderRadius: 0,
                                        activeBarBorderWidth: 0,
                                        colorItemBgSelected: "#e6f4ff",
                                        itemSelectedBg: "#258bce",
                                        itemSelectedColor: "rgba(0,0,0,.65)",

                                        itemHoverBg: "transparent",
                                        colorItemBgHover: "transparent",
                                        itemHoverColor: "#225f96",
                                        itemBg: '#F2F5F9',
                                        activeBarHeight: 4,
                                        colorSplit: "rgba(0, 5, 5, 0.06)",
                                        lineType: 'solid',
                                        boxShadowSecondary: '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)'
                                    }
                                }
                            }}
                        >
                            <BrowserRouter>
                                <LocationViewer/>
                                <Routes>
                                    <Route path="landing" element={<Landing/>}/>
                                    <Route path="/" element={<HomePage/>}>
                                        <Route index element={<Description/>}/>
                                        <Route path="description" element={<Description/>}/>
                                        <Route path="1688" element={<Page1688/>}>
                                            <Route index element={<Navigate to="search" replace/>}/>
                                            <Route path="search" element={<Search1688Content/>}/>
                                            <Route path="products/:productId" element={<Product/>}/>
                                            <Route path="factories/:memberId" element={<Factories1688/>}/>
                                            <Route path="products1688byUrl" element={<Product />} />
                                        </Route>
                                        <Route path="account" element={<Account/>}>
                                            <Route index element={<Navigate to="login" replace/>}/>
                                            <Route path="login" element={<LoginPage/>}/>
                                            <Route path="register" element={<RegisterPage/>}/>
                                        </Route>
                                        <Route path="cabinet" element={<Cabinet/>}>
                                            <Route index element={<Navigate to="orders" replace/>}/>
                                            <Route path="orders" element={<OrdersPage/>}/>
                                            <Route path="cart" element={<Cart/>}/>
                                            <Route path="order-success" element={<CreateOrderSuccess/>}/>
                                            <Route path="order/:orderId" element={<OrderView/>}/>
                                            <Route path="profile" element={<Profile/>}/>
                                        </Route>
                                        <Route path='/callback' element={<AuthCallback/>}/>
                                    </Route>
                                </Routes>
                            </BrowserRouter>
                        </ConfigProvider>
                    </AntApp>
                </WindowWidthProvider>
            </HelmetProvider>
        </AuthProvider>
    );
}

export default App;
