import React, {useRef, useState} from 'react'

import {Icon} from "@iconify/react";
import './HomeHeaderMobile.less';
import {Container} from "../../components";
import {Link, useNavigate} from "react-router-dom";
import {Flex, Input} from "antd";
import {TruckOutlined} from "@ant-design/icons";
import {sendMetric} from "../../utils/metrics";
import {searchProductsAsync, uploadImageAsync} from "../1688/search/search1688Slice";
import {useDispatch, useSelector} from "react-redux";
import {useAuth} from "../../auth/useAuth";
import { LuUserRound, LuUserRoundCheck  } from "react-icons/lu";
import { LuPackageSearch, LuPackage, LuShoppingBag } from "react-icons/lu";
import { LiaShoppingBagSolid } from "react-icons/lia";
import {Camera, Search as SearchIcon} from "lucide-react";

const { Search } = Input;


const HomeHeaderMobile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fileInputRef = useRef(null)

    const productsResult = useSelector(state => state.search1688.productsResult)
    const [productKeywords, setProductKeywords] = useState(productsResult.keywords);
    const [originalProductKeywords, setOriginalProductKeywords] = useState(productsResult.originalKeywords);
    const [productSort, setProductSort] = useState(productsResult.sort);

    const products = useSelector(state => state.cart.items);
    const cartItemsCnt = products.length;

    const {isAuthenticated = () => { }, tokenRevoke = () => { }} = useAuth();

    const handleClick = () => {
        fileInputRef.current.value = "";
        fileInputRef.current.click();
    };
    const handleUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;
        dispatch(uploadImageAsync({file}));
        navigate("/1688/search");
    };

    const handleSearch = () => {
        const regex = /^https?:\/\/(detail\.)?1688\.com(\/.*)?$/i;
        if(regex.test(productKeywords)){ //если это ссылка на страницу товара на 1688.com
            navigate("/1688/products1688byUrl", { state: { productUrl : productKeywords}})
        } else {
            dispatch(searchProductsAsync({
                keywords: productKeywords,
                originalKeywords: undefined,
                pagination: undefined,
                sort: productSort
            }));
            navigate("/1688/search")
        }
    };

    const handleLogout = async () => {
        tokenRevoke();
    }

    return(
        <div className='header-mobile d-lg-none'>
            <Container  className='header-mobile-container'>
                <Flex className="header-mobile_top">
                    <div className="header-logo">
                        <Link to={`/`}>
                            <img src='/logo.png' alt='logo' />
                        </Link>
                    </div>

                    <div className="header-icons">
                        <ul className="icon-list">
                            <li className="icon-item m-icon-search">
                                <LuPackage />
                            </li>
                            {!isAuthenticated() &&
                                <li className="icon-item icon-user">
                                    <Link to="/account/login" >
                                        <LuUserRound />
                                    </Link>
                                </li>
                            }
                            {isAuthenticated() &&
                                <li className="icon-item icon-user">
                                    <Link onClick={() => handleLogout()} >
                                        <LuUserRoundCheck />
                                    </Link>
                                </li>
                            }
                            <li className="icon-item icon-header-cart">
                                <Link to="/cabinet/cart" className="position-relative">
                                    <LuShoppingBag  />
                                    <span className="number">{cartItemsCnt}</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </Flex>
                <div className="header-mobile_bottom">
                    <div className="header-search m-header-search active">
                        <Input placeholder="Наберите наименование товара"
                               variant='borderless'
                               className='noborder fs-16'
                               onChange={(e) => {
                                   setProductKeywords(e.target.value)
                                   setOriginalProductKeywords(undefined);
                               }}
                               onPressEnter={(e) => {
                                   sendMetric('reachGoal', 'SearchProductButtonClick');
                                   handleSearch();
                               }}
                               onBlur={() => {
                                   document.body.style.zoom = "1"; // Сбрасываем масштаб
                               }}

                               suffix={
                                    <div className='actions'>
                                        <Camera className='upload-icon' onClick={() => {
                                            sendMetric('reachGoal', 'SearchProductButtonClick');
                                            handleClick();
                                        }}/>

                                        <SearchIcon onClick={(e) => {
                                            sendMetric('reachGoal', 'SearchProductButtonClick');
                                            handleSearch();
                                        }}/>
                                    </div>
                               }
                        />
                    </div>
                </div>
            </Container>
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleUpload}
                accept="image/*"
                style={{ display: "none" }}
            />
        </div>
    )
}

export default HomeHeaderMobile;
