import {useEffect, useRef, useState} from 'react';

import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

import './ProductImageViewer.less';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import {useSelector, useDispatch} from "react-redux";
import {setCurrentImageIndex} from "./product1688Slice";


const ProductImageViewer = ({product}) => {

    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const galleryRef = useRef(null);
    const currentImageIndex = useSelector(state => state.product1688.currentImageIndex);


    const {mainImgs=[]} = product;
    const images = mainImgs.map(img => {
        return {
            original: img,
            thumbnail : img
        }
    })

    useEffect(() => {
        if (currentImageIndex !== -1 && galleryRef.current) {
            galleryRef.current.slideToIndex(currentImageIndex);
        }
    }, [currentImageIndex]);

    const handleImageClick = (index) => {
        dispatch(setCurrentImageIndex(index));
        setIsOpen(true);
    };

    return (
        <div className='image-gallery-wrapper'>
            <ImageGallery
                ref={galleryRef}
                items={images}
                showFullscreenButton={false}
                showPlayButton={false}
                showNav={false}
                onClick={(e) => handleImageClick(e.target.getAttribute('data-index'))}
            />

            {isOpen && (
                <Lightbox
                    slides={images.map((img) => ({ src: img.original }))}
                    open={isOpen}
                    index={currentImageIndex}
                    close={() => setIsOpen(false)}
                    on={{ view: ({ index }) => setCurrentImageIndex(index) }}
                />
            )}
        </div>
    );
};

export default ProductImageViewer;
