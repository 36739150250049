import React from 'react'

import './ProductDetail.less'
import ProductSkus from "./ProductSkus";
import ProductPrice from './ProductPrice';
import ProductDelivery from "./ProductDelivery";


const ProductDetail = ({product = {}}) => {
    const {shopInfo ={}, title} = product;
    const {shop_name} = shopInfo;

    return(
        <div className='product-detail'>
            <div className='shop-info'>
                {shop_name}
            </div>
            <h1 className='title'>
                {title}
            </h1>
            <ProductPrice product={product}/>
            <ProductDelivery {...product} />
            <ProductSkus product={product} />
        </div>
    )
}

export default ProductDetail;
