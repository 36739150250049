import React from 'react'
import './ProductDelivery.less'

const ProductDelivery = ({deliveryInfo ={}}) => {
    const {location} = deliveryInfo;

    return(
        <div className='product_delivery-wrapper'>
            <h3 className='prop-title'>
                Доставка
            </h3>
            <div>
                {location}
            </div>
        </div>
    )
}

export default ProductDelivery;
